.MuiDialog-paper {
  min-width: 400px;
  min-height: 300px;

  .MuiDialogTitle-root {
    padding-bottom: 0 !important;
  }

  .attach-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .new-intent-btn {
      font-size: .8em;
      text-align: center;
      border-radius: 1.5em;
      border: 1px solid #2BA5D4;
      color: #2BA5D4;
      padding: 5px 10px;
      background: white;
      cursor: pointer;
    }
  }
}

.MuiAutocomplete-root {
  width: 100%;
}

.MuiAutocomplete-listbox {
  li {
    padding: 4px 0 4px 10px !important;
  }
}
