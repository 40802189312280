.main {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .app-bar {
    z-index: 1;
    background: #FFFFFF;
    position: fixed;
    width: 97%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em 0;
    border-bottom: 1px solid gray;

    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #2BA5D4;

      img {
        cursor: pointer;
        width: 200px;
        height: 100px;
      }

      .list {
        display: flex;
        justify-content: center;

        .MuiTypography-root {
          display: flex;
          width: 100%;
          margin-right: 1em;
        }

        .active {
          font-weight: bold !important;
        }

        .MuiTypography-root:hover {
          cursor: pointer;
          font-weight: bold !important;
        }
      }
    }

    .logOut-btn {
      width: 7em;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #E53333;
      border-radius: 25px;
      color: white;
      font-size: .9em;
      padding: 10px 20px;
      cursor: pointer;

      svg {
        margin-right: .4em;
      }
    }
  }

  .panel {
    display: flex;
    flex-direction: column;
    padding: 1em 2em 2em;
    margin-top: 7em;
  }
}
