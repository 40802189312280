.MuiFormHelperText-root {
  color: #E53333 !important;
}

.MuiAutocomplete-listbox {
  li {
    padding: 4px 0 4px 10px !important;
  }
}

.MuiDialog-paper {
  min-height: 100px !important;
}

.MuiTextField-root {
  margin-top: .5em !important;
}