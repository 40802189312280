.overlay {
  position: relative;
  width: 100%;

  .MuiFormHelperText-root {
    color: #2BA5D4 !important;
  }

  .internal-box {
    width: 100% !important;
  }

  .delete-button {
    align-self: flex-end;
    position: absolute;
    margin-top: -18px;
    margin-right: -18px;
  }

  .update-spinner {
    position: absolute;
    align-self: center;
    margin-top: -100px;
    margin-left: 180px;
  }
}