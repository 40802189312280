.dialog_box {

  .title {
    text-align: center;
  }

  .MuiDialog-paperScrollPaper {
    max-height: 700px;
  }

  .MuiFormHelperText-root {
    color: #E53333 !important;
  }

  .logo {
    align-self: center;
    width: 100px;
    height: 100px;
    margin-top: .5em;
  }

  .sketchPicker {
    width: 380px;
  }

  .themeColorContainer {
    display: flex;
    margin-left: 24px;
  }

  .enableMessageButtonSwitch {
    display: flex;
    margin-left: 24px;
    margin-bottom: 10px;
  }

  .switchLabel {
    margin-top: 9px;
  }

  .themeColorField {
    display: flex;
    cursor: pointer;
  }

  .themeColor {
    border-radius: 5px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-left: 20px;
  }

  .MuiDialog-paper {
    .upload_root {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      min-height: 80px;
      border: 1px solid black;

      span {
        width: 50%;
        margin-right: 1em;
      }

      b {
        width: 50%;
        margin: 0 2em 0 1em;
      }
    }

    .upload_message_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      min-height: 80px;
      margin-top: 10px;
      border: 1px solid black;

      span {
        width: 50%;
        margin-right: 1em;
      }

      b {
        width: 50%;
        margin: 0 2em 0 1em;
      }
    }
  }
}