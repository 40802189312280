.WrapDiv {
  display: flex;
  height: 100vh;

  .Box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .logo {
      position: absolute;
      top: .5em;
      left: .5em;

      img {
        margin: 2em 4em 0;
        width: 13em;
        height: 5em;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      height: 25em;
      width: 44em;

      .form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        form {
          width: 100%;
          height: 100%;
        }


        .form-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          height: 100%;

          button {
            margin-top: 2em;
          }

          .MuiFormHelperText-root {
            color: #E53333 !important;
          }

          .MuiTextField-root {
            width: 80%;
          }
        }
      }

      .headingDiv {
        height: 5rem;
        background: deepskyblue;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-align: center;
        display: flex;
        align-content: center;

        h1 {
          color: #FFFFFF;
          margin-left: 1em;
        }
      }
    }
  }
}


