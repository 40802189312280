.search-bar {
  display: flex;
  width: 250px;
  align-items: center;
  padding: 0.2em 0.625em;
  height: 2.625em;
  border-radius: 1em;
  border: 1px solid black;

  svg {
    margin-right: 0.625em;
    opacity: 0.5;
  }
}