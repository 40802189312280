.intent-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 10px #0000001A;
  border-radius: 14px;
  margin-bottom: 1.5em;
  width: 300px;
  min-height: 170px;
  max-height: 170px;
  position: relative;

  .delete-spinner {
    position: absolute;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
    background-color: gray;
  }

  .cLabel {
    color: white;
    box-sizing: border-box;
    width: 300px;
    background: #2BA5D4 0 0 no-repeat padding-box;
    border-radius: 14px 14px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 2em;
  }

  .btn2 {
    width: fit-content;
    text-align: center;
    border: 1px solid #2BA5D4;
    color: #2BA5D4;
    font-size: .8em;
    margin-top: 1em;
    border-radius: 22px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .info {
    display: flex;
    justify-content: flex-end;
  }
}

