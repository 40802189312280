.organisation-info {
  display: flex;
  flex-direction: column;
  padding: 1em;

  .organisation-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 1em;
  }

  .organisation-intents {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr));
    grid-row-gap: 1em;
    grid-column-gap: 5em;
    margin-top: 2em;

    .intent-attach-card {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 14px;
      border: 1px dashed #2BA5D4;
      margin-bottom: 1.5em;
      width: 298px;
      height: 168px;
      cursor: pointer;

      h6 {
        display: flex;
        align-items: center;
        color: #2BA5D4 !important;

        svg {
          margin-right: .3em;
        }
      }
    }
  }

  .abs-center {
    position: absolute;
    bottom: 50%;
    right: 50%;
  }
}