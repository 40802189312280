.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  text-align: center;
  margin-top: 1em;
}

.question-header {
  display: flex;
  align-items: center;
  padding-bottom: 1em;

  .intent-info {
    margin-left: 2em;
    display: flex;
    flex-direction: column;

    h5 {
      margin-bottom: .3em;
    }

    span {
      color: gray !important;
    }
  }

  .MuiIconButton-colorPrimary:hover {
    background-color: white !important;
  }
}

.abs-center {
  position: absolute;
  bottom: 50%;
  right: 50%;
}

.question-card {
  padding: 12px;
  width: 700px !important;
  margin: 1em;
  position: relative;

  .spinner {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: gray;
    opacity: 0.4;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.red-border {
  border: 1px solid red;
}