.organisation {
  display: flex;
  flex-direction: column;
  padding: 1em;
  height: 100%;

  .no-org {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      display: flex;
      align-items: flex-end;

      .add-btn {
        margin-left: 1.5em;
        font-size: .8em;
        text-align: center;
        border-radius: 1.5em;
        border: 1px solid #2BA5D4;
        color: #2BA5D4;
        padding: 5px 10px;
        background: white;
        cursor: pointer;
      }
    }
  }

  .organisation-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr));
    grid-row-gap: 1em;
    grid-column-gap: 5em;
    margin-top: 2em;
  }

  .abs-center {
    position: absolute;
    bottom: 50%;
    right: 50%;
  }
}